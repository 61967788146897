var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-container",
        [
          _c(
            "v-form",
            {
              ref: "form",
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "px-4",
                      attrs: {
                        cols: "12",
                        sm: "12",
                        md: _vm.superUsuario ? "8" : "12"
                      }
                    },
                    [
                      _vm.isExtraSmall || _vm.isSmall
                        ? _c(
                            "v-card-title",
                            {
                              staticClass:
                                "pa-3 text-center grey--text font-weight-black",
                              staticStyle: {
                                display: "flex",
                                "justify-content": "center"
                              }
                            },
                            [_vm._v(" Editar Usuário ")]
                          )
                        : _vm._e(),
                      _c(
                        "v-row",
                        {
                          class: !_vm.isExtraSmall && !_vm.isSmall ? "mt-4" : ""
                        },
                        [
                          _c(
                            "v-col",
                            {
                              class: _vm.isExtraSmall ? "pb-0" : "py-0",
                              attrs: { cols: "12", sm: "12", md: "12" }
                            },
                            [
                              _c(
                                "v-card-text",
                                { class: !_vm.isExtraSmall ? "mx-3" : "" },
                                [_vm._v(" Nome do Usuário ")]
                              ),
                              _c("v-text-field", {
                                attrs: {
                                  solo: "",
                                  "prepend-icon": !_vm.isExtraSmall
                                    ? "mdi-text-account"
                                    : "",
                                  name: "Nome",
                                  label: "Nome",
                                  type: "text",
                                  counter: "",
                                  maxlength: "45",
                                  hint:
                                    "Tamanho máximo 45 (não permite números e acentos)",
                                  rules: _vm.nameRulesConfirmation,
                                  required: ""
                                },
                                model: {
                                  value: _vm.usuario.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.usuario, "name", $$v)
                                  },
                                  expression: "usuario.name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {},
                        [
                          _c(
                            "v-col",
                            {
                              class: _vm.isExtraSmall ? "py-0" : "py-0",
                              attrs: {
                                cols: "12",
                                sm: "12",
                                md: "12",
                                lg: "12"
                              }
                            },
                            [
                              _c(
                                "v-card-text",
                                { class: !_vm.isExtraSmall ? "mx-3" : "" },
                                [_vm._v(" Login do Usuário ")]
                              ),
                              _vm.isSuperAdminRecuperi
                                ? _c("v-text-field", {
                                    attrs: {
                                      solo: "",
                                      "prepend-icon": !_vm.isExtraSmall
                                        ? "mdi-account-circle"
                                        : "",
                                      name: "login",
                                      label: "Login",
                                      type: "text",
                                      "data-cy": "usuarioLogin",
                                      rules: _vm.loginRulesAdminConfirmation,
                                      required: ""
                                    },
                                    on: {
                                      blur: function($event) {
                                        return _vm.verificarPreenchimentoLoginAdmin()
                                      }
                                    },
                                    model: {
                                      value: _vm.usuario.ds_login,
                                      callback: function($$v) {
                                        _vm.$set(_vm.usuario, "ds_login", $$v)
                                      },
                                      expression: "usuario.ds_login"
                                    }
                                  })
                                : _c("v-text-field", {
                                    attrs: {
                                      solo: "",
                                      "prepend-icon": !_vm.isExtraSmall
                                        ? "mdi-account-circle"
                                        : "",
                                      name: "login",
                                      label: "Login",
                                      type: "text",
                                      "data-cy": "usuarioLogin",
                                      rules: _vm.loginRulesConfirmation,
                                      readonly: "",
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.usuario.ds_login,
                                      callback: function($$v) {
                                        _vm.$set(_vm.usuario, "ds_login", $$v)
                                      },
                                      expression: "usuario.ds_login"
                                    }
                                  })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              class: _vm.isExtraSmall ? "py-0" : "py-0",
                              attrs: {
                                cols: "12",
                                sm: "12",
                                md: "12",
                                lg: "12"
                              }
                            },
                            [
                              _c(
                                "v-card-text",
                                { class: !_vm.isExtraSmall ? "mx-3" : "" },
                                [_vm._v(" E-mail do Usuário ")]
                              ),
                              _c("v-text-field", {
                                attrs: {
                                  solo: "",
                                  "prepend-icon": !_vm.isExtraSmall
                                    ? "email"
                                    : "",
                                  name: "Email",
                                  label: "Email",
                                  type: "text",
                                  "data-cy": "signinPasswordField",
                                  rules: _vm.emailRulesConfirmation,
                                  required: ""
                                },
                                model: {
                                  value: _vm.usuario.ds_email,
                                  callback: function($$v) {
                                    _vm.$set(_vm.usuario, "ds_email", $$v)
                                  },
                                  expression: "usuario.ds_email"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              class: _vm.isExtraSmall ? "py-0" : "py-0",
                              attrs: { cols: "12", sm: "12", md: "12", lg: "6" }
                            },
                            [
                              _c(
                                "v-card-text",
                                { class: !_vm.isExtraSmall ? "mx-3" : "" },
                                [_vm._v(" Tipo do Usuário ")]
                              ),
                              _c("v-select", {
                                attrs: {
                                  solo: "",
                                  color: "success",
                                  items: _vm.tpUsuario,
                                  label: "Tipo de Usuário",
                                  required: "",
                                  "prepend-icon": !_vm.isExtraSmall
                                    ? "mdi-chevron-right"
                                    : ""
                                },
                                model: {
                                  value: _vm.usuario.tp_usuario,
                                  callback: function($$v) {
                                    _vm.$set(_vm.usuario, "tp_usuario", $$v)
                                  },
                                  expression: "usuario.tp_usuario"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              class: _vm.isExtraSmall ? "py-0" : "py-0",
                              attrs: { cols: "12", sm: "12", md: "12", lg: "6" }
                            },
                            [
                              _c(
                                "v-card-text",
                                { class: !_vm.isExtraSmall ? "mx-3" : "" },
                                [_vm._v(" Agrupador ")]
                              ),
                              _c("v-select", {
                                attrs: {
                                  solo: "",
                                  disabled:
                                    _vm.loadingAgrupadores ||
                                    _vm.usuarioAgrupador != null,
                                  loading: _vm.loadingAgrupadores,
                                  items: _vm.agrupadores,
                                  label: "Agrupador",
                                  "prepend-icon": !_vm.isExtraSmall
                                    ? "mdi-chevron-right"
                                    : "",
                                  "item-text": "nm_agrupador",
                                  "item-value": "ds_agrupador",
                                  "data-cy": "usuarioAgrupador"
                                },
                                model: {
                                  value: _vm.usuario.ds_agrupador,
                                  callback: function($$v) {
                                    _vm.$set(_vm.usuario, "ds_agrupador", $$v)
                                  },
                                  expression: "usuario.ds_agrupador"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              class: _vm.isExtraSmall ? "pb-6" : "",
                              attrs: { cols: "12", sm: "4", md: "4" }
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "d-flex justify-center" },
                                [
                                  _c("v-switch", {
                                    staticClass: "ml-3",
                                    attrs: {
                                      outlined: "",
                                      color: "success",
                                      label: "Está ativo?",
                                      required: "",
                                      "data-cy": "btnStatusUsuario"
                                    },
                                    model: {
                                      value: _vm.usuario.st_ativo,
                                      callback: function($$v) {
                                        _vm.$set(_vm.usuario, "st_ativo", $$v)
                                      },
                                      expression: "usuario.st_ativo"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "4", md: "4" } },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass:
                                                "d-flex justify-center"
                                            },
                                            [
                                              _c("v-switch", {
                                                attrs: {
                                                  outlined: "",
                                                  color: "success",
                                                  label: "Usuario de API",
                                                  required: "",
                                                  "data-cy": "usuarioAPI"
                                                },
                                                model: {
                                                  value: _vm.isUserApi,
                                                  callback: function($$v) {
                                                    _vm.isUserApi = $$v
                                                  },
                                                  expression: "isUserApi"
                                                }
                                              }),
                                              !_vm.isExtraSmall
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "ma-2",
                                                      attrs: {
                                                        elevation: "0",
                                                        small: "",
                                                        fab: ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "grey lighten-2"
                                                              }
                                                            },
                                                            "v-icon",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [_vm._v(" help ")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      " Usuários de API são usados exclusivamente para integrações. Selecione essa opção para definir se o usuário é de API. "
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "4", md: "4" } },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass:
                                                "d-flex justify-center"
                                            },
                                            [
                                              _c("v-switch", {
                                                attrs: {
                                                  outlined: "",
                                                  color: "success",
                                                  disabled: !_vm.isUsuarioLite,
                                                  label: "Apenas Leitura",
                                                  required: "",
                                                  "data-cy": "usuarioReadonly"
                                                },
                                                model: {
                                                  value:
                                                    _vm.usuario
                                                      .is_lite_readonly,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.usuario,
                                                      "is_lite_readonly",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "\n                                                usuario.is_lite_readonly\n                                            "
                                                }
                                              }),
                                              !_vm.isExtraSmall
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "ma-2",
                                                      attrs: {
                                                        elevation: "0",
                                                        small: "",
                                                        fab: ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "grey lighten-2"
                                                              }
                                                            },
                                                            "v-icon",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [_vm._v(" help ")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "Opção exclusiva para Usuários Lite. Selecione esta opção para definir se o Usuário será apenas de consulta. Isso implica que este usuário não poderá emitir comandos para títulos, podendo apenas visualizá-los. "
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.superUsuario
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "12", md: "4" } },
                        [
                          _vm.alterarSenha
                            ? _c(
                                "v-card",
                                {
                                  staticClass: "grey lighten-4 text-center",
                                  staticStyle: {
                                    display: "grid",
                                    padding: "24px 24px 8px 24px"
                                  },
                                  attrs: { "min-height": "100%" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "padding-bottom": "16px",
                                        "justify-content": "space-between",
                                        "align-items": "center"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-card-text",
                                        {
                                          staticClass: "text-left",
                                          class: !_vm.isExtraSmall ? "pa-3" : ""
                                        },
                                        [
                                          _vm._v(
                                            " Informe abaixo a nova senha para esse usuário: "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-center align-center"
                                        },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function({
                                                      on,
                                                      attrs
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "mr-3 white--text",
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                  "x-small": "",
                                                                  fab: ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.gerarSenhaAutomatica()
                                                                  }
                                                                }
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v("mdi-key")
                                                            ])
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                3404914843
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  " Gerar senha automaticamente para o novo usuário "
                                                )
                                              ])
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                "x-small": "",
                                                fab: "",
                                                disabled:
                                                  _vm.validarDisableEditarSenha
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.alterarSenha = false
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-close")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-text-field", {
                                    attrs: {
                                      solo: "",
                                      "prepend-icon": !_vm.isExtraSmall
                                        ? "lock"
                                        : "",
                                      autocomplete: "new-password",
                                      value: _vm.userPassword,
                                      label: "Crie uma senha",
                                      hint:
                                        "Sua senha precisa conter no mín. 12 caracteres: maiúsculas, números e caracteres especiais (exemplo: @,!,#)",
                                      "append-icon": _vm.mostrarSenha
                                        ? "mdi-eye"
                                        : "mdi-eye-off",
                                      type: _vm.mostrarSenha
                                        ? "text"
                                        : "password",
                                      rules: _vm.passwordRules,
                                      "data-cy": "usuarioPwd"
                                    },
                                    on: {
                                      "click:append": function($event) {
                                        _vm.mostrarSenha = !_vm.mostrarSenha
                                      },
                                      input: function($event) {
                                        return _vm.$refs.passwordConfirmation.validate()
                                      }
                                    },
                                    nativeOn: {
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "tab",
                                            9,
                                            $event.key,
                                            "Tab"
                                          )
                                        )
                                          return null
                                        return _vm.$refs.passwordConfirmation.focus()
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "progress",
                                          fn: function() {
                                            return [
                                              _vm.custom
                                                ? _c("v-progress-linear", {
                                                    attrs: {
                                                      value: _vm.progress,
                                                      absolute: "",
                                                      height: "7"
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      3343315850
                                    ),
                                    model: {
                                      value: _vm.usuario.password,
                                      callback: function($$v) {
                                        _vm.$set(_vm.usuario, "password", $$v)
                                      },
                                      expression: "usuario.password"
                                    }
                                  }),
                                  _c("v-text-field", {
                                    ref: "passwordConfirmation",
                                    attrs: {
                                      solo: "",
                                      "prepend-icon": !_vm.isExtraSmall
                                        ? "lock"
                                        : "",
                                      name: "new-passaword",
                                      label: "Repita a nova senha",
                                      type: _vm.mostrarSenha
                                        ? "text"
                                        : "password",
                                      "append-icon": _vm.mostrarSenha
                                        ? "mdi-eye"
                                        : "mdi-eye-off",
                                      rules: _vm.passwordRulesConfirmation,
                                      required: "",
                                      "data-cy": "usuarioPwdConfirm"
                                    },
                                    on: {
                                      "click:append": function($event) {
                                        _vm.mostrarSenha = !_vm.mostrarSenha
                                      }
                                    },
                                    model: {
                                      value: _vm.usuario.passwordConfirmation,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.usuario,
                                          "passwordConfirmation",
                                          $$v
                                        )
                                      },
                                      expression: "usuario.passwordConfirmation"
                                    }
                                  })
                                ],
                                1
                              )
                            : _c(
                                "v-card",
                                {
                                  staticClass: "grey lighten-4 text-center",
                                  attrs: { "min-height": "100%" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "ma-3 pa-3",
                                          attrs: {
                                            elevation: "4",
                                            size: "120",
                                            color: "grey lighten-4--text"
                                          }
                                        },
                                        [_vm._v("mdi-lock")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "green white--text ma-3 pa-3",
                                          attrs: {
                                            "data-cy": "liberarModalSenha"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.alterarSenha = true
                                            }
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-lock-reset")
                                          ]),
                                          _vm._v(
                                            _vm._s(
                                              _vm.isExtraSmall
                                                ? "Alterar Senha"
                                                : "Alterar Senha do Usuário"
                                            )
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }