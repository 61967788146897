var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "80vw",
        persistent: "",
        scrollable: "",
        color: "background",
        transition: "dialog-bottom-transition"
      },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "pa-0" },
            [
              _c(
                "v-toolbar",
                { attrs: { flat: "", dark: "", dense: "", color: "primary" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: { click: _vm.fecharModal }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                  _c("v-toolbar-title", { staticClass: "pa-0" }, [
                    _vm._v(" Histórico do Usuário ")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "py-5 px-6 text-justify content-style" },
            [
              _c("div", { staticClass: "pb-4" }, [
                _c(
                  "span",
                  { staticClass: "text-h6 font-weight-medium black--text" },
                  [
                    _vm._v(
                      "Consulta de histórico de ações do usuário " +
                        _vm._s(
                          `${_vm.usuario.name} (Id: ${_vm.usuario.id_user})`
                        ) +
                        " nos últimos 30 dias."
                    )
                  ]
                )
              ]),
              _c("v-data-table", {
                staticClass: "elevation-1 pa-3",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.historicos,
                  "item-key": "historicos",
                  "footer-props": {
                    itemsPerPageOptions: [10, 20, 50, -1],
                    itemsPerPageText: "Históricos por página:"
                  },
                  "data-cy": "tabelaHistoricos"
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.historico_datahora",
                    fn: function(props) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.formatarDataHoraMoment(
                                props.item.historico_datahora,
                                "DD/MM/YYYY HH:mm"
                              )
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            { staticClass: "justify-end" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ml-4",
                  attrs: { "data-cy": "fecharConfirmacaoModal" },
                  on: { click: _vm.fecharModal }
                },
                [_vm._v(" Fechar ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }